<template>
  <v-card outlined tile :loading="loading">
    <v-card-title>Заявка от {{ document.partner.name }}</v-card-title>
    <div class="ma-2">
      <v-btn color="primary" @click.prevent="uploadWindow = true">
        Передать документы
      </v-btn>
    </div>
    <v-expansion-panels class="pa-2">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-file" disable-icon-rotate>Заявление и реквизиты заявителя</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list link dense>
            <v-list-item @click.prevent="documentDialog = true">
              <v-list-item-content>
                <v-list-item-title>Заявление на технологическое присоединение</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="customerDialog = true">
              <v-list-item-content>
                <v-list-item-title>Реквизиты заявителя</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-folder" disable-icon-rotate>Документы заявителя ({{ document.files.length }})</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn
            class="ma-2"
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="downloadZipHandler"
          >
            Скачать архивом
            <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
            </template>
          </v-btn>
          <v-list link dense>
            <v-list-item
              v-for="(item, i) in document.files"
              :key="i"
              :href="
          path + 'images/' + item.createdBy._id.toString() + '/' + item.fileName
        "
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>({{
                    item.createdAt | date('datetime')
                  }}) {{ item.fileName.slice(37) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon>mdi-information</v-icon>
              </span>
                  </template>
                  <span>{{ fileTip[item.tag] }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-folder" disable-icon-rotate>Документы выданные сетевой организацией ({{ document.resultFiles.length }})</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list link dense>
            <v-list-item
              v-for="(item, i) in document.resultFiles"
              :key="i"
              :href="
            path +
              'images/' +
              item.createdBy._id.toString() +
              '/' +
              item.fileName
          "
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.tag }}</v-list-item-title>
                {{ item.subType }}
              </v-list-item-content>
              <v-list-item-action> </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-folder" disable-icon-rotate>Документы гарантирующего поставщика ({{ document.sbitFiles.length }})</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list link dense>
            <v-list-item
                v-for="(item, i) in document.sbitFiles"
                :key="i"
                :href="
          path + 'images/' + item.createdBy._id.toString() + '/' + item.fileName
        "
                target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.fileName.slice(37) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon>mdi-information</v-icon>
              </span>
                  </template>
                  <span>{{ item.tag }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="uploadWindow">
      <v-card>
        <v-card-title>Загрузка документов</v-card-title>
        <v-card-text>
          <v-card-text>
            <v-file-input
              v-model="uploadFiles"
              dense
              outlined
              multiple
              accept="image/png,
                image/jpeg,
                image/jpg,
                application/pdf,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/vnd.visio"
              prepend-icon="mdi-image"
              label="Файл"
              hint="Выберите до 10 файлов"
              persistent-hint
            ></v-file-input>
            <v-select
              v-model="uploadType"
              dense
              outlined
              label="Вид документа"
              :items="uploadTypes"
            />
            <v-btn color="primary" @click.prevent="uploadHandler" :disabled="loading" class="mr-2"
            >Загрузить файлы</v-btn>
            <v-btn @click.prevent="uploadWindow=false">Отмена</v-btn>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="documentDialog">
      <connection-dialog
        :document="document"
      />
    </v-dialog>
    <v-dialog v-model="customerDialog">
      <v-card>
        <v-card-title>Реквизиты заявителя</v-card-title>
        <v-card-text v-if="document.partner.orgType === 0">
          <div class="text-to-print">
            <div class="form-header">
              <div style="text-transform: uppercase">{{ document.partner.name }}</div>
            </div>
            <div class="form-body">
              <p>
                <b>Сокращенное наименование:</b> {{ document.partner.shortName }}
              </p>
              <p>
                <b>Представитель:</b> {{ document.partner.director }}
              </p>
              <p>
                <b>ОГРН:</b> {{ document.partner.ogrn }}
              </p>
              <p>
                <b>Дата присвоения ОГРН:</b> {{ document.partner.creationDate }}
              </p>
              <p>
                <b>ИНН:</b> {{ document.partner.inn }}
              </p>
              <p>
                <b>КПП:</b> {{ document.partner.kpp }}
              </p>
              <p>
                <b>Бансковские реквизиты:</b> {{ document.partner.bank }}
              </p>
              <p>
                <b>Адрес:</b> {{ document.partner.address }}
              </p>
              <p>
                <b>Телефон:</b> {{ document.partner.telephone }}
              </p>
              <p>
                <b>eMail:</b> {{ document.partner.email }}
              </p>
            </div>
          </div>
        </v-card-text>
        <v-card-text v-if="document.partner.orgType === 1">
          <div class="text-to-print">
            <div class="form-header">
              <div style="text-transform: uppercase">{{ document.partner.name }}</div>
            </div>
            <div class="form-body">
              <p>
                <b>Паспорт серия, номер:</b> {{ document.partner.passportNum }}
              </p>
              <p>
                <b>Кем выдан:</b> {{ document.partner.passportOrg }}
              </p>
              <p>
                <b>Дата выдачи:</b> {{ document.partner.creationDate }}
              </p>
              <p>
                <b>ИНН:</b> {{ document.partner.inn }}
              </p>
              <p>
                <b>СНИЛС:</b> {{ document.partner.snils }}
              </p>
              <p>
                <b>Бансковские реквизиты:</b> {{ document.partner.bank }}
              </p>
              <p>
                <b>Адрес:</b> {{ document.partner.address }}
              </p>
              <p>
                <b>Телефон:</b> {{ document.partner.telephone }}
              </p>
              <p>
                <b>eMail:</b> {{ document.partner.email }}
              </p>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined small dark color="blue" @click.prevent="printText">
            <v-icon>
              mdi-printer
            </v-icon>
            Распечатать реквизиты
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import ConnectionDocument from "../connection/Detail/Document.vue";

const PATH = process.env.VUE_APP_GRAPHQL;
const PATH_UPLOAD = process.env.VUE_APP_UPLOADIMAGE;
const PATH_ZIP = process.env.VUE_APP_ZIP;

export default {
  name: "OperationsWindow",
  props: ['id'],
  components: {
    'connection-dialog': ConnectionDocument
  },
  watch: {
    id(value) {
      this.fetchDocument(value);
    }
  },
  computed: {
    token() {
      return localStorage.getItem('token');
    },
    role() {
      return this.$store.state.role;
    },
    ...mapState({ universal: state => state.univarsal }),
  },
  data: () => ({
    path: process.env.VUE_APP_REST,
    uploadWindow: false,
    documentDialog: false,
    customerDialog: false,
    uploadType: 'Договор',
    uploadFiles: [],
    uploadTypes: ['Договор', 'Счет', 'Другое'],
    loadingImages: false,
    overlay: false,
    imgLoadProgress: 0,
    fileTip: {
      plan:
        'План расположения энергопринимающего устройства присоединяемого к сетям сетевой организации',
      energySchema:
        'Однолинейная схема электрических сетей присоединяемых к электрическим сетям сетевой организации, номинальный класс напряжения которых составляет 35 кВ и выше, с указанием возможности резервирования от собственных источников энергоснабжения (включая резервирование для собственных нужд) и возможности переключения нагрузок (генерации) по внутренним сетям заявителя',
      ownership:
        'Копия документа, подтверждающего право собственности или иное предусмотренное законом основание на объект капитального строительства (нежилое помещение в таком объекте капитального строительства) и (или) земельный участок, на котором расположены (будут располагаться) объекты заявителя, либо право собственности или иное предусмотренное законом основание на энергопринимающие устройства (для заявителей, планирующих осуществить технологическое присоединение энергопринимающих устройств потребителей, расположенных в нежилых помещениях многоквартирных домов или иных объектах капитального строительства, - копия документа, подтверждающего право собственности или иное предусмотренное законом основание на нежилое помещение в таком многоквартирном доме или ином объекте капитального строительства)',
      ukAgreement:
        'Копия документа, подтверждающего согласие организации, осуществляющей управление многоквартирным домом, при наличии у такой организации соответствующих полномочий либо при ее отсутствии или отсутствии у нее полномочий согласие общего собрания владельцев жилых помещений многоквартирного дома на организацию присоединения нежилого помещения отдельными линиями от вводного устройства (вводно-распределительного устройства, главного распределительного щита), установленного на вводе питающей линии сетевой организации в соответствующее здание или его обособленную часть (если для соответствующего нежилого помещения проектом на многоквартирный дом не предусмотрено индивидуальное вводно-распределительное устройство с непосредственным присоединением к питающей линии сетевой организации)',
      misc: 'Прочий документ'
    },
    loading: false,
    document: {
      partner: {
        _id: 0,
        name: '',
        orgType: 0,
        shortName: '',
        director: ''
      },
      files: [],
      resultFiles: [],
      sbitFiles: [],
    }
  }),
  mounted() {
    this.fetchDocument();
  },
  methods: {
    async fetchDocument() {
      if (this.id) {
        this.loading = true;
        try {
          const graphqlQuery = {
            query: `
              query FetchDocument($id: String!) {
                connectionFetch(id: $id) {
                  ${this.universal.defaultDataToFetch.connection}
                }
              }
            `,
            variables: {
              id: this.id
            }
          };

          const response = await fetch(PATH, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.token,
            },
            body: JSON.stringify(graphqlQuery)
          });
          const responseData = await response.json();

          this.document = responseData.data.connectionFetch;
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    printText() {
      window.print();
    },
    ...mapMutations(['setMessageData']),
    async downloadZipHandler() {
      console.log(this.document.files);
      try {
        const res = await fetch(PATH_ZIP + `?id=${this.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token
          }
        });
        if (res.status === 200) {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'images.zip';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // afterwards we remove the element again
        } else {
          throw new Error('Error');
        }
      } catch (error) {
        this.setMessageData({
          message: 'Ошибка при загрузке файлов!'
        });
      }
    },
    async uploadHandler() {
      if (this.uploadFiles) {
        const validSize = [];
        const dataBodys = [];
        // Подготовка данных для отправки. Multer ждет не более 10 штук каждого вида переменных ниже
        if (Array.isArray(this.uploadFiles)) {
          // Если множественная загрузка файлов
          this.uploadFiles.forEach(f => {
            if (f.size > 2000000) {
              validSize.push(f.name);
            }
            const dataBody = new FormData();
            dataBody.append('files', f);
            dataBody.append('description', null);
            dataBody.append('userId', this.$store.state.userId);
            dataBody.append('tag', this.uploadType);
            dataBodys.push(dataBody);
          });
        } else {
          // Если одиночная загрузка файлов
          // Проверка на размер файла. Не более 2 мегабайт
          if (this.uploadFiles.size > 2000000) {
            validSize.push(this.uploadFiles.name);
          }
          const dataBody = new FormData();
          dataBody.append('files', this.uploadFiles);
          dataBody.append('tag', this.uploadType);
          dataBody.append('description', null);
          dataBody.append('userId', this.$store.state.userId);
          dataBodys.push(dataBody);
        }

        if (validSize.length) {
          this.setMessageData({
            message:
              'Следующие файлы превышают размер 2МБ: ' +
              validSize.map(name => name + ' ') +
              ' Пожалуйста сократите размер и повторите попытку.',
            type: 'snackbar'
          });

          this.loadingImages = false;
          this.overlay = false;
          return false;
        }

        const images = []; // Сюда сохраним данные о вновь созданных изображениях в базе данных
        // Делаем запрос к API на принятие новых файлов
        this.loading = true;
        this.overlay = true;
        this.loadingImages = true;
        this.imgLoadProgress = 0;
        try {
          const step = Math.floor(100 / +dataBodys.length);
          for (const dataBody of dataBodys) {
            const result = await fetch(PATH_UPLOAD, {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + this.$store.state.token
              },
              body: dataBody
            });
            const resData = await result.json();

            if (resData.errors) {
              let errMessage = '';
              resData.errors.forEach(e => {
                errMessage += e.message + ' \n';
              });
              // throw new Error(errMessage);
            } else {
              images.push(resData[0]);
              this.imgLoadProgress = this.imgLoadProgress + step;
            }
          }
        } catch (error) {
          console.log(error.message);
        }
        this.loading = false;
        this.loadingImages = false;
        this.overlay = false;

        // Тут уже имеем загруженные картинки.
        // Добавляем _id вновь загруженных картинок в массив файлов

        const resultFiles = [];

        for (const image of images) {
          resultFiles.push(image._id);
        }

        const graphqlQuery = {
          query: `mutation ConnectionUpdateSbitFiles($id: String!, $sbitFiles: [String]!) {
            connectionUpdateSbitFiles(id: $id, sbitFiles: $sbitFiles) {
              _id
              sbitFiles {
                _id
                fileName
                filePath
                tag
                subTitle
                subType
                createdBy { _id name }
              }
            }
          }`,
          variables: {
            id: this.document._id.toString(),
            sbitFiles: resultFiles
          }
        };

        this.loading = true;
        // const result = await sendRequest(token, query, variables);
        // // Сообщить о результате
        // let message = 'Изменение сохранено';
        // if (result.error) {
        //   message = `Ошибка: ${result.error}`;
        //   this.loading = false;
        // } else {
        //   // Получены обновленные данные
        //   this.$emit(
        //     'onUpdate',
        //     result.data.connectionUpdateResultFiles.resultFiles
        //   );
        // }
        const response = await fetch(PATH, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
          },
          body: JSON.stringify(graphqlQuery)
        });
        const responseData = await response.json();

        this.loading = false;
        this.uploadWindow = false;
        await this.fetchDocument();
      }
    }
  }
}
</script>

<style scoped>
.form-header {
  padding: 20px 0;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.form-body {
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  color: black;
}
.show-on-print {
  display: none;
}
@media print {
  .show-on-print {
    display: block;
  }
  .text-to-print {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    z-index: 9999999;
  }
}
</style>
